<template>
    <!--  Content Start -->
    <div class="sabc_contact_outer">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <div class="sabc_contact_inner">
                    <h1>Contact Us</h1>
                    <div class="sabc_contact_form_otr">
                      <div class="sabc_contact_form_inr">
                        <div class="send_mail" v-if="sendMessgar">
                        Your detail has been sent to specific department. Support Team will contact you soon
                        <i @click="closeMessage()">x</i>
                        </div>
                        <div class="mail-form" id="mail-form" >
                            <p>Send an email directly to a specific department or business unit, select below:</p>
                            <form v-on:submit.prevent="submitForm">
                                <div class="sabc_contact_form_drop">
                                    <select id="mail_type" name="mail_type" v-model="form.mail_type" class="sabc_contact_form_control">
                                        <option value="SABC Sport">SABC Sport</option>
                                        <option value="Advertising">Advertising</option>
                                    </select>
                                </div>
                                <label for="name">Your Name:</label>
                                <input class="sabc_contact_form_control" required type="text" id="name" name="name" v-model="form.name"/>
                                <label for="email">Your Email:</label>
                                <input class="sabc_contact_form_control" required type="email" id="email" name="email" v-model="form.email"/>
                                <label for="phone">Contact Number:</label>
                                <input class="sabc_contact_form_control" required type="text"  id="phone" name="phone" v-model="form.phone"/>
                                <label for="message">Your Message: </label>
                                <textarea class="sabc_contact_form_control sabc_contact_form_msg" id="message" name="message" v-model="form.message"></textarea>
                                <!-- <img class="sabc_contact_form_capcha" :src="require('@/assets/images/sabc_contact_form_capcha.webp')" alt="sabc_contact_form_capcha" height="74" width="300"/> -->
                                <label v-if="captchaErrorText" for="captcha" class="captchaLable">The reCAPTCHA was invalid. Go back and try it again.</label>
                                <vue-recaptcha @verify="onVerify" @expired="onExpired" sitekey="6LdQe68UAAAAAOqGZCRcfH3YyboxvQZzlm3TdCFJ"></vue-recaptcha>
                                <button type="submit" :disabled="form.name == '' || form.email == '' || form.phone == ''">SEND</button>
                            </form>
                        </div>
                      </div>
                      <div class="sabc_contact_address">
                        <h3>Contact Details:</h3>
                        <ul>
                          <li class="sabc_contact_n">
                            <h4>
                              <label>Phone:</label>
                              <span>011 714-9111</span>
                            </h4>
                            <h4>
                              <label>Fax:</label>
                              <span> 011 714-9744</span>
                            </h4>
                          </li>
                          <li class="sabc_contact_m">
                            <h4>
                              <label>SABC Sport:</label>
                              <span><a href="mailto:sport@sabc.co.za"  target="_blank">sport@sabc.co.za</a></span>
                            </h4>
                            <h4>
                              <label>Advertising:</label>
                              <span><a href="mailto:sabcsales@sabc.co.za" target="_blank">sabcsales@sabc.co.za</a></span>
                            </h4>
                          </li>
                          <li class="sabc_contact_a">
                            <h4>
                              <label>Address:</label>
                            </h4>
                            <h4>
                              <span>
                                Cnr. of Artillery & Henley Rd
                                Auckland Park
                                Johannesburg. Private Bag X1
                                Auckland Park 2006
                              </span>
                            </h4>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <SideBar :editorsPick="editorsPick" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
            </div>
        </div>
    </div>
    <!--  Content end -->
</template>

<script>
    import SideBar from '@/components/common/sidebar/sidebar';
    import { VueRecaptcha } from 'vue-recaptcha';
    import VueCompositionAPI from '@vue/composition-api'

    export default {
        name: 'contact',
        data() {
            return {
                editorsPick: [],
                bannerImages : [],
                bannerEmbed : [],
                sendMessgar: false,
                captchaVerify: false,
                captchaVerifyResponse: '',
                captchaErrorText: false,
                form : {
                    mail_type: 'SABC Sport',
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                },
            }
        },
        components: {SideBar, VueRecaptcha },
        mounted() {
            this.getContactPageData();
       },
        serverPrefetch() {
            this.getContactPageData();
        },
        computed: {
        },
        metaInfo() {
           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;

                let title = this.$getSafe(() => ss.contact_us_meta_title);
                let description = this.$getSafe(() => ss.contact_us_meta_description);
                let keywords = this.$getSafe(() => ss.contact_us_meta_keywords);

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }

        },

        methods: {
            getContactPageData(offset) {
                this.url = this.$GetContactPageData;
                return this.$axios.get(this.url)
                        .then(response => {
                            if (response.data) {
                                this.editorsPick = response.data.editorsPick;
                                this.bannerImages = response.data.bannerImages;
                                this.bannerEmbed = response.data.bannerEmbed;
                                // this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },
            submitForm(){
                this.url = this.$PostContactPage;
                this.sendMessgar = false;
                this.captchaErrorText = false;
                if(this.captchaVerify){
                    this.$axios.post(this.url, this.form)
                        .then((res) => {
                            this.sendMessgar = true;
                            this.form = {mail_type: 'SABC Sport',name: '',email: '',phone: '',message: ''};
                            window.scrollTo({top: 0,left: 0,behavior: 'smooth'});
                            // this.resetRecaptcha();
                            document.getElementById('mail-form').classList.add('d-none');
                        })
                        .catch((error) => {
                            console.log(error)
                        }).finally(() => {
                            //Perform action in always
                        });
                }else{
                    this.captchaErrorText = true;
                }
            },
            closeMessage(){
                this.sendMessgar = false;
            },
            onVerify: function (response) {
                this.captchaErrorText = false;
                console.log('Verify: ' + response)
                console.log('123: ' + this.captchaErrorText)
                this.captchaVerify = true;
                this.captchaVerifyResponse = response;
            },
            onExpired: function () {
                console.log('Expired')
                this.captchaVerify = false;
                this.captchaVerifyResponse = '';
            },
            resetRecaptcha() {
                this.$refs.recaptcha.reset() // Direct call reset method
            },
        }
    }

</script>
<style scope>
.send_mail{background: #01ab4d47;padding: 10px;border-radius: 8px;margin-bottom: 10px;position: relative;}
.send_mail i{position: absolute;right: 10px;top: 0px;font-size: 15px;cursor: pointer;font-style: normal;}
button[disabled] {opacity: 0.6;}
.captchaLable{color: red !important;font-size: 12px !important;}
</style>
<style lang="scss">
@import '~@/assets/scss/contact.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>
